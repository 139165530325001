import { Box, BoxProps, CSSWithMultiValues, RecursiveCSSSelector } from "@biblioteksentralen/react";
import { ExtraRichTextBlock, RichTextBlock } from "@libry-content/types";
import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import { BlockContentContextProvider } from "./BlockContentContext";
import { LinkSerializer } from "./LinkSerializer";
import { TextGallerySerializer } from "./TextGallerySerializer";
import { TextLinkButtonSerializer } from "./TextLinkButtonSerializer";
import { TextTableSerializer } from "./TextTableSerializer";
import VideoEmbedSerializer from "./VideoEmbedSerializer";
import { StyleMap, mapStyles } from "./styleMap";

export const richTextStyle: RecursiveCSSSelector<CSSWithMultiValues> = {
  maxWidth: "100%",
  ">": {
    "*": {
      maxWidth: "30rem",
      marginLeft: "auto",
      marginRight: "auto",
      lineHeight: "1.5",
    },
    p: {
      marginBottom: "1em",
    },
    h2: {
      "&:not(:first-child)": {
        marginTop: "calc(2vmin + 2rem)",
      },
      marginBottom: "0.25em",
      fontSize: "1.5em",
      fontWeight: 600,
    },
    h3: {
      marginTop: "1em",
      fontSize: "1em",
      fontWeight: 600,
    },
    "ul,ol": {
      paddingLeft: "1.5em",
      marginBottom: "2.5em",
      "ul, ol": {
        marginBottom: 0,
      },
      li: {
        paddingLeft: "0.3em",
      },
    },
    ul: {
      listStyle: "disc",
      ul: {
        listStyle: "circle",
      },
    },
    ol: {
      listStyle: "decimal",
    },
  },
};

const components: Partial<PortableTextReactComponents> = {
  types: {
    table: TextTableSerializer,
    gallery: TextGallerySerializer,
    linkButton: TextLinkButtonSerializer,
    videoEmbed: VideoEmbedSerializer,
  },
  marks: {
    link: LinkSerializer,
  },
};
type Props = {
  blocks?: RichTextBlock | ExtraRichTextBlock;
  styleMap?: StyleMap;
  logInternalLinks?: boolean;
  galleryBackgroundColors?: Record<string, string>;
} & BoxProps;

function BlockContent({ blocks, styleMap, logInternalLinks, galleryBackgroundColors, ...chakraProps }: Props) {
  return (
    <BlockContentContextProvider logInternalLinks={logInternalLinks} galleryBackgroundColors={galleryBackgroundColors}>
      <Box sx={richTextStyle} fontSize="lg" {...chakraProps}>
        <PortableText value={mapStyles(styleMap, blocks)} components={components} />
      </Box>
    </BlockContentContextProvider>
  );
}

export default BlockContent;
