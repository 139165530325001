import { Box, BoxProps, CSSObject, colors } from "@biblioteksentralen/react";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { SanityImageCore, SanityImageCoreProps } from "./SanityImageCore";

type Props = {
  borderRadius?: BoxProps["borderRadius"];
  hideCaption?: boolean;
  _groupHover?: CSSObject;
  imageWrapperProps?: BoxProps;
  captionProps?: BoxProps;
  chakraProps?: BoxProps;
} & SanityImageCoreProps;

// This component both uses next image for lazy loading and other next-opimizations, and calls sanity with image dimensions to utilize image hotspot and reduce network trafic 🍀
const SanityImage = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Box as="figure" height="100%" {...props.chakraProps}>
      {/* Måtte ha wrapper for at blur/placeholder også skal få border-radius */}
      <Box
        background={`linear-gradient(135deg, ${colors.grey05}, ${colors.grey15})`}
        overflow="hidden"
        isolation="isolate" // bugfix-safari - problem med overflow i kombinasjon med scale-animasjon https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
        height="100%"
        borderRadius={props.borderRadius ?? "xl"}
        lineHeight={0}
        {...props.imageWrapperProps}
      >
        <Box _groupHover={props._groupHover ?? { transform: "scale(1.03)" }} transition=".5s">
          <SanityImageCore
            image={props.image}
            aspectRatio={props.aspectRatio}
            resolution={props.resolution}
            quality={props.quality}
            customNextImageProps={props.customNextImageProps}
          />
        </Box>
      </Box>
      {props.image?.creator && !props.hideCaption && (
        <Box
          fontStyle="italic"
          textAlign="end"
          marginRight="0.1rem"
          fontSize="sm"
          opacity={0.8}
          {...props.captionProps}
        >
          {t("Foto: {opphavsPerson}", { opphavsPerson: props.image.creator })}
        </Box>
      )}
    </Box>
  );
};

export default SanityImage;
