import { BsChevronDown } from "@biblioteksentralen/icons";
import { AspectRatio, Box, BoxProps, Button, colors, Heading, Spinner, Text, VStack } from "@biblioteksentralen/react";
import { VideoEmbed as VideoEmbedType } from "@libry-content/types";
import { useEffect, useRef, useState } from "react";
import { useIsIframeReady } from "../utils/hooks/useIsIframeReady";
import { useTranslation } from "../utils/hooks/useTranslation";
import { BsChevronUp } from "./BsChevronUp";
import BlockContent from "./blockContent/BlockContent";

const useDescription = (value: VideoEmbedType | undefined) => {
  const { ts } = useTranslation();
  const description = ts(value?.description);
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);

  const [descriptionNoOfLines, setDescriptionNoOfLines] = useState<2 | undefined>(2);
  const [isExpanded, setIsExpanded] = useState(false);
  const [canExpandDescription, setCanExpandDescription] = useState<boolean | undefined>(undefined);

  // Use scroll height to check for expandability on first render (once expanded it can no longer be used)
  useEffect(() => {
    if (!descriptionRef.current || typeof canExpandDescription !== "undefined") return;
    setCanExpandDescription((descriptionRef.current?.scrollHeight ?? 0) > (descriptionRef.current?.clientHeight ?? 0));
  }, [canExpandDescription]);

  const handleClickExpand = () => {
    if (!isExpanded) {
      setIsExpanded(true);
      setDescriptionNoOfLines(undefined);
    } else {
      setIsExpanded(false);
      setDescriptionNoOfLines(2);
    }
  };

  return {
    description,
    descriptionNoOfLines,
    handleClickExpand,
    isExpanded,
    canExpandDescription,
    descriptionRef,
  };
};

type Props = { value: VideoEmbedType | undefined } & BoxProps;

export const VideoEmbed = ({ value, borderRadius, ...boxProps }: Props) => {
  const { t, ts } = useTranslation();
  const [isReady, iframeRef] = useIsIframeReady(value?.embedUrl);

  const title = `${value?.service} video: ${ts(value?.title)}`;

  const { description, descriptionNoOfLines, handleClickExpand, isExpanded, canExpandDescription, descriptionRef } =
    useDescription(value);

  if (!value?.embedUrl) return null;

  return (
    <VStack margin="2rem auto" width="100%" maxWidth="unset !important" {...boxProps}>
      <Box
        position="relative"
        width="100%"
        height="100%"
        marginTop="0!important"
        maxWidth={{ base: "100%", md: "80%" }}
      >
        <AspectRatio ratio={16 / 9} borderRadius={borderRadius ?? "xl"} overflow="hidden">
          <iframe title={title} allowFullScreen ref={iframeRef} />
        </AspectRatio>
        {!isReady && (
          <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
            <Spinner size="xl" />
          </Box>
        )}
      </Box>
      {!!description && (
        <VStack
          as="span"
          alignItems="flex-start"
          margin="0.5rem 1rem 0 !important"
          width="100%"
          maxWidth={{ base: "100%", md: "80%" }}
          padding="0 1rem"
        >
          <Heading as="h3" fontSize="md" margin="0.5rem 0 !important">
            <Text as="span">{t("Tekstalternativ til video")}</Text>
          </Heading>
          <Text
            as="span"
            noOfLines={descriptionNoOfLines}
            marginTop="0 !important"
            padding="0"
            fontSize={{ base: "sm", sm: "md" }}
            ref={descriptionRef}
            sx={{ p: { marginBottom: "1rem", "&:last-child": { marginBottom: 0 } } }}
          >
            <BlockContent blocks={description} />
          </Text>
          {canExpandDescription && (
            <Button
              size="xs"
              variant="link"
              color={colors.grey80}
              width="fit-content"
              padding="0"
              marginTop="0.25rem!important"
              onClick={handleClickExpand}
              fontSize={{ base: "sm", sm: "md" }}
              rightIcon={isExpanded ? <BsChevronUp /> : <BsChevronDown />}
              sx={{ ":focus:not(:focus-visible)": { outline: 0, boxShadow: "none" } }}
            >
              {isExpanded ? t("Vis mindre") : t("Vis mer")}
            </Button>
          )}
        </VStack>
      )}
    </VStack>
  );
};
